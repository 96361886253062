<template>
  <div>
    <Form ref="form" :rows="rows" v-model="detail" />
    <Navigation
      ref="navigation"
      class="mb-12"
      :navigation-item="navigationItem"
    />
    <RowLink :links="links" />
    <FooterButton :loading="loading" @click="submit">保存</FooterButton>
  </div>
</template>
<script>
import FooterButton from "@/components/common/button/FooterButton.vue";
import Navigation from "@/components/navigation/index.vue";
import Form from "@/components/common/form/index.vue";
import RowLink from "@/components/select/rowLink.vue";
import { emptyAreaApi } from "@/utils/api.js";
export default {
  components: {
    FooterButton,
    Navigation,
    Form,
    RowLink,
  },
  data() {
    return {
      id: "",
      loading: false,
      rows: [
        {
          key: "kjDealerName",
          label: "所属经销商",
        },
        { key: "kjRegionTown", label: "所属市县" },
        {
          key: "businessMaster",
          label: "所属业务员",
          gap: true,
        },
      ],
    };
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    navigationItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    links() {
      return [
        {
          label: "近期拜访记录",
          path: `/visit/list?type=emptyArea&id=${this.id}`,
        },
      ];
    },
  },
  activated() {
    this.id = this.$route.params.id;
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      try {
        await emptyAreaApi.infoSave({
          id: this.id,
          blankVillageCoordinate: `${this.$refs.navigation.currentLocation.lng},${this.$refs.navigation.currentLocation.lat}`,
          blankVillagePosition: this.$refs.navigation.value,
        });
        this.$toast("保存成功");
      } catch (err) {
        this.$toast(err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
