<template>
  <div>
    <FilterWrap
      v-show="showFilter"
      v-model="filterValue"
      @reset="filterValue = {}"
      @confirm="resetList"
    />
    <div v-show="!showFilter" class="empty-area">
      <HeaderTabs :tabs="tabs" @click="handleTabClick"></HeaderTabs>
      <div class="search-wrap">
        <SearchWrap v-model="searchTotal" @search="resetList"></SearchWrap>
        <div class="sort-wrap" v-show="false">
          <img
            @click="showFilterDialog"
            src="../../assets/images/common/filter.png"
            alt=""
          />
        </div>
      </div>
      <div v-if="activeName === '空白村'" class="list-wrap">
        <CustomCard
          class="mb-12"
          v-for="(item, index) in list"
          :key="index"
          :title="item.kjVillageName"
          :content="formatCardContent(item)"
        >
          <div class="action-wrap">
            <CustomButton size="mini" @click.stop="navigateInfo(item.kjVillage)"
              >拜访</CustomButton
            >
          </div>
        </CustomCard>
      </div>
      <div v-else class="list-wrap">
        <CustomCard
          class="mb-12"
          v-for="(item, index) in list"
          :key="index"
          :title="item.kjTowninfoName"
          :content="formatCardContent2(item)"
        >
        </CustomCard>
      </div>
      <infinite-loading ref="infinite" @infinite="infiniteHandler">
        <span slot="no-more">
          暂无更多
        </span>
      </infinite-loading>
    </div>
  </div>
</template>
<script>
import HeaderTabs from "@/components/header/headerTabs";
import SearchWrap from "@/components/common/search";
import CustomCard from "@/components/common/card";
import CustomButton from "@/components/common/button/CustomButton.vue";
import InfiniteLoading from "vue-infinite-loading";
import { emptyAreaApi } from "@/utils/api.js";
import FilterWrap from "./Filter";
import { getDealerId } from "@/utils/user.js";
export default {
  components: {
    HeaderTabs,
    SearchWrap,
    CustomCard,
    CustomButton,
    InfiniteLoading,
    FilterWrap,
  },
  data() {
    return {
      activeName: "空白村",
      tabs: [{ title: "空白村" }, { title: "空白乡镇" }],
      searchTotal: "",
      list: [],
      pageNo: 1,
      pageSize: 5,
      total: 0,
      showFilter: false,
      filterValue: {},
      sortValue: "",
    };
  },
  activated() {
    try {
      this.filterValue =
        JSON.parse(window.localStorage.getItem("shidanli-filter")) || {};
      window.localStorage.setItem("shidanli-filter", "");
    } catch (err) {
      this.filterValue = {};
    }
    this.resetList();
  },
  methods: {
    // 切换tab
    handleTabClick(tab) {
      this.activeName = tab;
      this.searchTotal = "";
      this.filterValue = {};
      this.sortValue = "";
      this.resetList();
    },
    // 搜索
    resetList() {
      this.showFilter = false;
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = [];
      this.pageNo = 1;
      this.total = 0;
    },
    async infiniteHandler($state) {
      try {
        await this.search();
      } catch (err) {
        //
      }
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },
    async search(clear) {
      if (clear) {
        this.resetList();
      }
      let response = {};
      if (this.activeName === "空白村") {
        response = await emptyAreaApi.list({
          "page.pageNo": this.pageNo,
          "page.pageSize": this.pageSize,
          searchTotal: this.searchTotal,
          // filter
          searchOfficeHead: "",
          searchOfficeBranch: "",
          searchOfficeDepartment: "",
          businessMaster: "",
          searchProvince: "",
          searchCity: "",
          searchRegion: "",
          kjTowninfo: "",
          ...this.filterValue,
          kjDealer: this.filterValue.kjDealer || getDealerId(),
        });
      } else {
        response = await emptyAreaApi.townList({
          "page.pageNo": this.pageNo,
          "page.pageSize": this.pageSize,
          searchTotal: this.searchTotal,
          // filter
          searchOfficeHead: "",
          searchOfficeBranch: "",
          searchOfficeDepartment: "",
          businessMaster: "",
          searchProvince: "",
          searchCity: "",
          searchRegion: "",
          kjTowninfo: "",
          ...this.filterValue,
          kjDealer: this.filterValue.kjDealer || getDealerId(),
        });
      }
      if (response.page.total && this.pageNo === 1) {
        this.$toast(`共${response.page.total}条数据`)
      }
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    },
    // revert
    revert() {},
    // 格式化
    formatCardContent(item) {
      return [
        { label: "所属市县", value: `${item.kjRegionName}.${item.kjTowninfoName}` },
        { label: "上次拜访时间", value: item.lastTime },
        { label: "拜访次数", value: `${item.visitNum || 0}次` },
      ];
    },
    formatCardContent2(item) {
      return [
        { label: "空白村", value: item.blankVillageNum },
        { label: "所属市县", value: item.kjRegionName },
      ];
    },
    // 拜访
    navigateInfo(id) {
      this.$router.push({ name: "EmptyAreaInfo", params: { id } });
    },
    showFilterDialog() {
      this.$router.push({ path: "/emptyArea/filter" });
    },
  },
};
</script>
<style lang="scss" scoped>
.empty-area {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  .search-wrap {
    padding: 10px 24px 10px 12px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sort-wrap {
      flex-shrink: 0;
      img {
        height: 14px;
        width: auto;
        margin-left: 20px;
      }
    }
  }
  .list-wrap {
    padding: 12px;
    .action-wrap {
      text-align: right;
    }
  }
}
</style>
