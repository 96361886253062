<template>
  <div class="filter-page">
    <Header class="mb-12" />
    <Form ref="form" :rows="rows" v-model="detail" />
    <FooterButton
      cancelLabel="重置"
      confirmLabel="确认"
      @cancel="reset"
      @confirm="confirm"
    />
  </div>
</template>
<script>
import Header from "@/components/header";
import FooterButton from "@/components/common/button/FooterDoubleButton.vue";
import Form from "@/components/common/form/index.vue";
export default {
  components: {
    FooterButton,
    Header,
    Form,
  },
  data() {
    return {
      detail: {},
      rows: [
        {
          key: "searchProvince",
          label: "省",
          type: "picker",
          dictType: "province",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.searchCity[0].fetchCity(
              this.detail.searchProvince
            );
            this.$refs.form.$refs.searchCity[0].reset();
            this.$refs.form.$refs.searchRegion[0].reset();
            this.$refs.form.$refs.kjTowninfo[0].reset();
          },
        },
        {
          key: "searchCity",
          label: "市",
          type: "picker",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.searchRegion[0].fetchRegion(
              this.detail.searchCity
            );
            this.$refs.form.$refs.searchRegion[0].reset();
            this.$refs.form.$refs.kjTowninfo[0].reset();
          },
        },
        {
          key: "searchRegion",
          label: "县",
          type: "picker",
          valueString: "id",
          labelString: "name",
          handleChange: () => {
            this.$refs.form.$refs.kjTowninfo[0].fetchTown(
              this.detail.searchRegion
            );
            this.$refs.form.$refs.kjTowninfo[0].reset();
          },
        },
        {
          key: "kjTowninfo",
          label: "乡镇",
          type: "picker",
          valueString: "id",
          labelString: "name",
        },
        {
          key: "kjDealer",
          label: "经销商",
          type: "picker",
          dictType: "dealer",
        },
      ],
    };
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    reset() {
      this.detail = {};
      // this.$emit("input", {});
    },
    confirm() {
      // this.$emit("input", this.detail);
      // this.$emit("confirm");
      window.localStorage.setItem(
        "shidanli-filter",
        JSON.stringify(this.detail || {})
      );
      this.$router.push({ path: "/emptyArea" });
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-page {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
}
</style>
